import { emptyRoleKey } from "@monorepo/informationSecurity/src/views/SystemAccesses/constants/emptyRoleKey";
import {
  AUTHORITY_SUB_KEY,
  CREATE_OBJECT_KEY,
  DELETE_OBJECT_KEY,
  EDIT_OBJECT_KEY,
  EXPORT_OBJECT_KEY,
  VIEW_OBJECT_KEY,
} from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/formAuthorities";
import { IChapter, IRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { convertRolesFromApi } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/convertingRolesApi";

const addAuthority = (chapter: IChapter, key: string) => {
  const result = [];
  if (chapter[key as keyof IChapter]) {
    result.push(chapter[`${key}${AUTHORITY_SUB_KEY}` as unknown as keyof IChapter]);
  }
  return result;
};

export const convertRolesToApi = (
  localRoles: { [key: string]: Partial<IRole> },
  defaultApiRoles: IRole[]
): {
  code: string | undefined;
  description: string;
  id: string | undefined;
  label: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roleAuthorities: any;
}[] => {
  const formatedApiRoles = convertRolesFromApi(defaultApiRoles);
  const resultRoles = Object.entries(localRoles).reduce((result, [key, element]) => {
    if (!formatedApiRoles[key]) {
      return {
        ...result,
        [key]: element,
      };
    }

    if (element.name !== formatedApiRoles[key].name) {
      return {
        ...result,
        [key]: element,
      };
    }
    const isChangedRoles = element.chapters?.some((chapter, index) => {
      return [CREATE_OBJECT_KEY, EDIT_OBJECT_KEY, DELETE_OBJECT_KEY, VIEW_OBJECT_KEY, EXPORT_OBJECT_KEY].some((keyObj) => {
        return chapter[keyObj as keyof IChapter] !== formatedApiRoles[key].chapters?.[index]?.[keyObj as keyof IChapter];
      });
    });

    if (isChangedRoles) {
      return {
        ...result,
        [key]: element,
      };
    }
    return result;
  }, {});

  return Object.entries(resultRoles).map(([id, role]) => {
    return {
      code: (role as Partial<IRole>).code || (role as Partial<IRole>).name,
      description: "",
      id: id.includes(emptyRoleKey) ? undefined : id,
      label: (role as Partial<IRole>).name,
      roleAuthorities:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (role as Partial<IRole>).chapters?.reduce((result: any[], chapter: IChapter) => {
          return [
            ...result,
            ...[CREATE_OBJECT_KEY, EDIT_OBJECT_KEY, DELETE_OBJECT_KEY, VIEW_OBJECT_KEY, EXPORT_OBJECT_KEY].reduce(
              (result: unknown[], key: string) => [...result, ...addAuthority(chapter, key)],
              []
            ),
          ];
        }, []) || [],
    };
  });
};
